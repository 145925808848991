// See: http://www.jacklmoore.com/notes/rounding-in-javascript/
function round(value, decimals) {
    return Number(Math.round(value+"e"+decimals)+"e-"+decimals);
}

function parseLocaleNumber(stringNumber, locale) {
    if (!stringNumber) return 0;
    const thousandSeparator = Intl.NumberFormat(locale).formatToParts(11111)[1].value;
    const decimalSeparator = Intl.NumberFormat(locale).formatToParts(1.1)[1].value;

    return parseFloat(stringNumber
        .replace(new RegExp("\\" + thousandSeparator, "g"), "")
        .replace(new RegExp("\\" + decimalSeparator), "."),
    );
}

export default {
    round: function (value, decimals) {
        return round(value, decimals);
    },
    parseLocaleNumber: parseLocaleNumber,
};